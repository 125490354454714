import { Terminal } from 'xterm'

type Command = (...args: string[]) => string | void

const builtInCommands: Record<string, Command> = {
  clear(this: Shell) {
    this.terminal.clear()
  },

  echo(this: Shell, ...args: string[]) {
    return args.join(' ')
  },

  pwd(this: Shell) {
    return this.currentPath
  },
}

export default class Shell {
  public currentPath = '/'

  constructor(public terminal: Terminal) {

  }

  public async process(line: string) {
    if (line.trim().length === 0) return
    const [cmd, ...args] = line.split(' ')
    const run = this.getBuiltInCommands(cmd)
    if (!run) {
      this.terminal.writeln('command not found: ' + cmd)
      return
    }
    const out = await run.apply(this, args)
    this.terminal.writeln(out)
  }



  getBuiltInCommands(cmd: string): Command | undefined {
    return builtInCommands[cmd]
  }
}
