import { Terminal } from 'xterm'
import { FitAddon } from 'xterm-addon-fit'
import { WebLinksAddon } from 'xterm-addon-web-links'
import LocalEchoController from 'local-echo'
import Shell from './shell'

const init = async () => {
  const terminal = new Terminal()
  const container = document.getElementById('container')
  terminal.loadAddon(new WebLinksAddon())
  const fitAddon = new FitAddon()
  terminal.loadAddon(fitAddon)
  const localEcho = new LocalEchoController()
  terminal.loadAddon(localEcho)

  terminal.open(container!)
  fitAddon.fit()

  const shell = new Shell(terminal)

  while (true) {
    const line = await localEcho.read('$ ')
    await shell.process(line)
  }
}

export default init
